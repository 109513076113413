"use client";

import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	HStack,
	Stack,
	Button,
	Heading,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { createAccountData } from "api";
import { IAccountFormData } from "connex2me-services/src/types";

export default function SignupLayout() {
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<IAccountFormData>();

	const onSubmit = async (data: IAccountFormData) => {
		await createAccountData(data);
	};

	return (
		<div className="App app-container">
			<Flex
				minH={"100vh"}
				align={"center"}
				justify={"center"}
				bg={useColorModeValue("gray.50", "gray.800")}
			>
				<Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
					<Stack align={"center"}>
						<Heading fontSize={"4xl"} textAlign={"center"}>
							Sign up
						</Heading>
						<Text fontSize={"lg"} color={"gray.600"}>
							to enjoy all of our cool features ✌️
						</Text>
					</Stack>
					<Box
						rounded={"lg"}
						bg={useColorModeValue("white", "gray.700")}
						boxShadow={"lg"}
						p={8}
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Stack spacing={4}>
								<HStack>
									<Box>
										<FormControl isInvalid={errors.companyName !== undefined}>
											<FormLabel htmlFor="companyName">Company Name</FormLabel>
											<Input
												type="text"
												id="companyName"
												name="companyName"
												{...register("companyName", {
													required: "This is required",
												})}
												placeholder="Company Name"
											/>
											<FormErrorMessage>
												Company Name is required
											</FormErrorMessage>
										</FormControl>
									</Box>
								</HStack>
								<HStack>
									<Box>
										<FormControl isInvalid={errors.industry !== undefined}>
											<FormLabel htmlFor="industry">Industry</FormLabel>
											<Input
												type="text"
												id="industry"
												name="industry"
												{...register("industry", {
													required: "This is required",
												})}
												placeholder="Industry"
											/>
											<FormErrorMessage>Industry is required</FormErrorMessage>
										</FormControl>
									</Box>
								</HStack>
								<HStack>
									<Box>
										<FormControl isInvalid={errors.brandName !== undefined}>
											<FormLabel htmlFor="brandName">Brand Name</FormLabel>
											<Input
												type="text"
												id="brandName"
												name="brandName"
												{...register("brandName", {
													required: "This is required",
												})}
												placeholder="Brand Name"
											/>
											<FormErrorMessage>
												Brand Name is required
											</FormErrorMessage>
										</FormControl>
									</Box>
								</HStack>
								<HStack>
									<Box>
										<FormControl
											mt={4}
											isInvalid={errors.website !== undefined}
										>
											<FormLabel htmlFor="website">Website</FormLabel>
											<Input
												type="text"
												id="website"
												name="website"
												{...register("website", {
													required: "This is required",
												})}
												placeholder="Website"
											/>
											<FormErrorMessage>Website is required</FormErrorMessage>
										</FormControl>
									</Box>
								</HStack>
								<Stack spacing={10} pt={2}>
									<Button
										loadingText="Submitting"
										type="submit"
										size="lg"
										bg={"blue.400"}
										color={"white"}
										_hover={{
											bg: "blue.500",
										}}
									>
										Sign up
									</Button>
								</Stack>
							</Stack>
						</form>
					</Box>
				</Stack>
			</Flex>
		</div>
	);
}
