"use client";

import {
	Box,
	useColorModeValue,
	Drawer,
	DrawerContent,
	useDisclosure,
} from "@chakra-ui/react";
import AccountNav from "components/layout/account-nav";
import SideBar from "components/layout/side-bar";

function AppLayout({ children }: { children: React.ReactNode }) {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<div className="App app-container">
			<Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
				<SideBar
					onClose={() => onClose}
					display={{ base: "none", md: "block" }}
				/>
				<Drawer
					isOpen={isOpen}
					placement="left"
					onClose={onClose}
					returnFocusOnClose={false}
					onOverlayClick={onClose}
					size="full"
				>
					<DrawerContent>
						<SideBar onClose={onClose} />
					</DrawerContent>
				</Drawer>
				<AccountNav onOpen={onOpen} />
				<Box ml={{ base: 0, md: 60 }} p="4">
					{children}
				</Box>
			</Box>
		</div>
	);
}

export default AppLayout;
