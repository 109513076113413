import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { SuperTokensConfig } from "./config";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppRouter from "./app-router";

const queryClient = new QueryClient();

SuperTokens.init(SuperTokensConfig);

function App() {
	return (
		<SuperTokensWrapper>
			<ChakraProvider>
				<QueryClientProvider client={queryClient}>
					<div className="App app-container">
						<AppRouter />
					</div>
				</QueryClientProvider>
			</ChakraProvider>
		</SuperTokensWrapper>
	);
}

export default App;
