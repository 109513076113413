import React from "react";
import {
	Grid,
	Flex,
	Center,
	Card,
	CardBody,
	Box,
	CardHeader,
} from "@chakra-ui/react";
import {
	TbBrandFacebook,
	TbBrandGoogle,
	TbBrandLinkedin,
} from "react-icons/tb";

export default function Connections() {
	const connectToLinkedin = () => {
		const clientId = "783etxcbha2wt6";
		const redirectUri = encodeURIComponent(
			"https://api.dev.connex2me.com/connect/linkedin"
		); // Replace with your redirect URI
		const state = encodeURIComponent("xyz"); // Optional: Protect against CSRF

		// LinkedIn OAuth authorization URL
		const authorizationUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid%2Cprofile%2Cw_member_social%2Cemail&state=${state}`;

		window.location.href = authorizationUrl;
	};

	const connectToFacebook = () => {
		const clientId = "389680020576584";
		const redirectUri = encodeURIComponent(
			"https://api.dev.connex2me.com/connect/facebook"
		); // Replace with your redirect URI
		const state = encodeURIComponent("xyz"); // Optional: Protect against CSRF

		// Facebook OAuth authorization URL
		const authorizationUrl = `https://www.facebook.com/v19.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}`;

		window.location.href = authorizationUrl;
	};

	return (
		<Center>
			<Grid templateColumns="repeat(4, 1fr)" gap={6} width="80%" mt="10" p="4">
				<Flex flexDirection="column">
					<Card title="Google" cursor={"pointer"}>
						<CardHeader>
							<Flex>
								<TbBrandGoogle size="24" />
								<Box ml={2}>Google</Box>
							</Flex>
						</CardHeader>
						<CardBody>
							<Flex>
								<Box fontSize={"xs"}>calendar</Box>
							</Flex>
						</CardBody>
					</Card>
				</Flex>
				<Flex flexDirection="column">
					<Card title="Linkedin" onClick={connectToLinkedin} cursor={"pointer"}>
						<CardHeader>
							<Flex>
								<TbBrandLinkedin size="24" />
								<Box ml={2}>Linkedin</Box>
							</Flex>
						</CardHeader>
						<CardBody>
							<Flex>
								<Box fontSize={"xs"}>profile</Box>
							</Flex>
						</CardBody>
					</Card>
				</Flex>
				<Flex flexDirection="column">
					<Card title="Facebook" onClick={connectToFacebook} cursor={"pointer"}>
						<CardHeader>
							<Flex>
								<TbBrandFacebook size="24" />
								<Box ml={2}>Facebook</Box>
							</Flex>
						</CardHeader>
						<CardBody>
							<Flex>
								<Box fontSize={"xs"}>profile</Box>
							</Flex>
						</CardBody>
					</Card>
				</Flex>
			</Grid>
		</Center>
	);
}
