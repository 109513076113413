"use client";

import {
	Box,
	CloseButton,
	Flex,
	Icon,
	useColorModeValue,
	BoxProps,
	FlexProps,
	Image,
} from "@chakra-ui/react";
import { FiTrendingUp, FiSettings } from "react-icons/fi";
import { TbSocial, TbDashboard } from "react-icons/tb";
import { FaCogs } from "react-icons/fa";
import { IconType } from "react-icons";
import logo from "assets/img/connex2me_logo.png";
import { useNavigate } from "react-router-dom";

interface LinkItemProps {
	name: string;
	to: string;
	icon: IconType;
}

interface NavItemProps extends FlexProps {
	to: string;
	icon: IconType;
	children: React.ReactNode;
}

interface SidebarProps extends BoxProps {
	onClose: () => void;
}

const LinkItems: Array<LinkItemProps> = [
	{ name: "Dashboard", to: "/", icon: TbDashboard },
	{ name: "Trending", to: "/", icon: FiTrendingUp },
	{ name: "Connections", to: "/connections", icon: TbSocial },
	{ name: "Integrations", to: "/integrations", icon: FaCogs },
	{ name: "Settings", to: "/settings", icon: FiSettings },
];

const NavButton = ({ to, icon, children, ...rest }: NavItemProps) => {
	const navigate = useNavigate();
	return (
		<Box
			as="a"
			onClick={() => {
				navigate(to);
			}}
			style={{ textDecoration: "none" }}
			_focus={{ boxShadow: "none" }}
		>
			<Flex
				align="center"
				p="4"
				mx="4"
				borderRadius="lg"
				role="group"
				cursor="pointer"
				_hover={{
					bg: "cyan.400",
					color: "white",
				}}
				{...rest}
			>
				{icon && (
					<Icon
						mr="4"
						fontSize="16"
						_groupHover={{
							color: "white",
						}}
						as={icon}
					/>
				)}
				{children}
			</Flex>
		</Box>
	);
};

const SideBar = ({ onClose, ...rest }: SidebarProps) => {
	return (
		<Box
			transition="3s ease"
			bg={useColorModeValue("white", "gray.900")}
			borderRight="1px"
			borderRightColor={useColorModeValue("gray.200", "gray.700")}
			w={{ base: "full", md: 60 }}
			pos="fixed"
			h="full"
			{...rest}
		>
			<Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
				<Image src={logo} />
				<CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
			</Flex>
			{LinkItems.map((link) => (
				<NavButton key={link.name} to={link.to} icon={link.icon}>
					{link.name}
				</NavButton>
			))}
		</Box>
	);
};

export default SideBar;
