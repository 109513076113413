import React, { createContext, useContext } from "react";
import { IUserContext } from "types";
import { useUserData } from "api";
import { Spinner } from "@chakra-ui/react";

const UserContext = createContext<IUserContext | undefined>(undefined);

export const useUser = () => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error("useUser must be used within a UserProvider");
	}
	return context;
};

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { isLoading, error, data } = useUserData();

	if (isLoading)
		return (
			<Spinner
				thickness="4px"
				speed="0.65s"
				emptyColor="gray.200"
				color="blue.500"
				size="xl"
			/>
		);

	if (error) return <div>Error: {error.message}</div>;

	return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};
