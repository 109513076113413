import { ThirdPartyPreBuiltUI } from "supertokens-auth-react/recipe/thirdparty/prebuiltui";
import ThirdParty, {
	Github,
	Google,
	Facebook,
} from "supertokens-auth-react/recipe/thirdparty";
import Session from "supertokens-auth-react/recipe/session";

export function getApiDomain() {
	const apiPort = process.env.REACT_APP_API_PORT || 3001;
	const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:${apiPort}`;
	return apiUrl;
}

export function getWebsiteDomain() {
	const websitePort = process.env.REACT_APP_WEBSITE_PORT || 3000;
	const websiteUrl =
		process.env.REACT_APP_WEBSITE_URL || `http://localhost:${websitePort}`;
	return websiteUrl;
}

export const SuperTokensConfig = {
	appInfo: {
		appName: "Connex2me",
		apiDomain: getApiDomain(),
		apiBasePath: "/auth",
		websiteDomain: getWebsiteDomain(),
	},
	recipeList: [
		ThirdParty.init({
			signInAndUpFeature: {
				providers: [Github.init(), Google.init(), Facebook.init()],
			},
		}),
		Session.init({
			sessionTokenFrontendDomain: ".connex2me.com",
			sessionTokenBackendDomain: ".connex2me.com",
		}),
	],
	//enableDebugLogs: true,
};

export const PreBuiltUIList = [ThirdPartyPreBuiltUI];
