import React from "react";
import {
	Grid,
	Flex,
	Center,
	Card,
	CardBody,
	Box,
	CardHeader,
} from "@chakra-ui/react";
import { TbBrandFacebook, TbCalendarStats } from "react-icons/tb";

export default function Integrations() {
	const connectToCalendly = () => {
		console.log("connectToCalendly");
	};

	return (
		<Center>
			<Grid templateColumns="repeat(4, 1fr)" gap={6} width="80%" mt="10" p="4">
				<Flex flexDirection="column">
					<Card title="Linkedin" onClick={connectToCalendly} cursor={"pointer"}>
						<CardHeader>
							<Flex>
								<TbCalendarStats size="24" />
								<Box ml={2}>Calendly</Box>
							</Flex>
						</CardHeader>
						<CardBody>
							<Flex>
								<Box fontSize={"xs"}>calendar</Box>
							</Flex>
						</CardBody>
					</Card>
				</Flex>
				<Flex flexDirection="column">
					<Card title="TypeForm" cursor={"pointer"}>
						<CardHeader>
							<Flex>
								<TbBrandFacebook size="24" />
								<Box ml={2}>TypeForm</Box>
							</Flex>
						</CardHeader>
						<CardBody>
							<Flex>
								<Box fontSize={"xs"}>profile</Box>
							</Flex>
						</CardBody>
					</Card>
				</Flex>
			</Grid>
		</Center>
	);
}
