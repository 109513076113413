import { IUserContext } from "../types";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { queryUser } from "./query/user";

export * from "./command/account";

export const useUserData = (): UseQueryResult<IUserContext> => {
	return useQuery<IUserContext>({
		queryKey: ["userContext"],
		queryFn: queryUser,
	});
};
