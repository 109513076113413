import { IUserContext } from "types";

const baseUrl = "https://api.dev.connex2me.com";

export const queryUser = async (): Promise<IUserContext> => {
	const endpoint = `${baseUrl}/account/getUserContext`;
	const response = await fetch(endpoint);
	if (response.status === 403) {
		return {} as IUserContext;
	}
	if (!response.ok) {
		throw new Error("Failed to fetch user context");
	}
	return response.json();
};
