import { IAccountFormData } from "connex2me-services/src/types";

const baseUrl = "https://api.dev.connex2me.com";

export const createAccountData = async (
	data: IAccountFormData
): Promise<void> => {
	try {
		const endpoint = `${baseUrl}/account/create`;
		const response = await fetch(endpoint, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				rid: "session",
			},
			body: JSON.stringify(data),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		const responseData = await response.json();
		console.log("Response:", responseData);
	} catch (error) {
		console.error("Error:", error);
	}
};
