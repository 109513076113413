import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import { PreBuiltUIList } from "./config";
import AppLayout from "./layouts/app-layout";
import SignupLayout from "./layouts/signup-layout";
import { useUser } from "contexts/useUserContext";
import { UserProvider } from "contexts/useUserContext";
import Dashboard from "./pages/dashboard";
import Profile from "pages/profile";
import Settings from "pages/settings";
import Billing from "pages/billing";
import Connections from "pages/connections";
import NotFound from "pages/notfound";
import Integrations from "pages/integrations";

function appLayoutWithAuth(Component: React.ComponentType) {
	return (
		<SessionAuth>
			<UserProvider>
				<AppLayout>
					<Component />
				</AppLayout>
			</UserProvider>
		</SessionAuth>
	);
}

function DefaultAppComponent() {
	const user = useUser();
	return user.organizationUser !== null ? (
		<AppLayout>
			<Dashboard />
		</AppLayout>
	) : (
		<SignupLayout />
	);
}

function AppRouter() {
	return (
		<Router>
			<Routes>
				{getSuperTokensRoutesForReactRouterDom(
					require("react-router-dom"),
					PreBuiltUIList
				)}
				<Route
					path="/"
					element={
						<SessionAuth>
							<UserProvider>
								<DefaultAppComponent />
							</UserProvider>
						</SessionAuth>
					}
				/>
				<Route path="/profile" element={appLayoutWithAuth(Profile)} />
				<Route path="/settings" element={appLayoutWithAuth(Settings)} />
				<Route path="/billing" element={appLayoutWithAuth(Billing)} />
				<Route path="/connections" element={appLayoutWithAuth(Connections)} />
				<Route path="/integrations" element={appLayoutWithAuth(Integrations)} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Router>
	);
}

export default AppRouter;
