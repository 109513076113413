"use client";

import {
	IconButton,
	Avatar,
	Box,
	Flex,
	HStack,
	VStack,
	useColorModeValue,
	Text,
	FlexProps,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Image,
} from "@chakra-ui/react";
import { FiMenu, FiBell, FiChevronDown } from "react-icons/fi";
import { signOut } from "supertokens-auth-react/recipe/session";
import { useUser } from "contexts/useUserContext";
import logo from "assets/img/connex2me_logo_sm.png";
import { useNavigate } from "react-router-dom";

interface IAccountNavProps extends FlexProps {
	onOpen: () => void;
}

const AccountNav = ({ onOpen, ...rest }: IAccountNavProps) => {
	const user = useUser();
	const navigate = useNavigate();

	async function handleSignout() {
		await signOut();
		window.location.href = "/";
	}

	return (
		<Flex
			ml={{ base: 0, md: 60 }}
			px={{ base: 4, md: 4 }}
			height="20"
			alignItems="center"
			bg={useColorModeValue("white", "gray.900")}
			borderBottomWidth="1px"
			borderBottomColor={useColorModeValue("gray.200", "gray.700")}
			justifyContent={{ base: "space-between", md: "flex-end" }}
			{...rest}
		>
			<IconButton
				display={{ base: "flex", md: "none" }}
				onClick={onOpen}
				variant="outline"
				aria-label="open menu"
				icon={<FiMenu />}
			/>

			<Image src={logo} display={{ base: "flex", md: "none" }} />

			<HStack spacing={{ base: "0", md: "6" }}>
				<IconButton
					size="lg"
					variant="ghost"
					aria-label="open menu"
					icon={<FiBell />}
				/>
				<Flex alignItems={"center"}>
					<Menu>
						<MenuButton
							py={2}
							transition="all 0.3s"
							_focus={{ boxShadow: "none" }}
						>
							<HStack>
								<Avatar
									size={"sm"}
									src={
										user.userIdpProfiles[0].picture
											? user.userIdpProfiles[0].picture
											: "https://bit.ly/broken-link"
									}
								/>
								<VStack
									display={{ base: "none", md: "flex" }}
									alignItems="flex-start"
									spacing="1px"
									ml="2"
								>
									<Text fontSize="sm">{user.userIdpProfiles[0].name}</Text>
									<Text fontSize="xs" color="gray.600">
										Admin
									</Text>
								</VStack>
								<Box display={{ base: "none", md: "flex" }}>
									<FiChevronDown />
								</Box>
							</HStack>
						</MenuButton>
						<MenuList
							bg={useColorModeValue("white", "gray.900")}
							borderColor={useColorModeValue("gray.200", "gray.700")}
						>
							<MenuItem
								onClick={() => {
									navigate("/profile");
								}}
							>
								Profile
							</MenuItem>
							<MenuItem
								onClick={() => {
									navigate("/settings");
								}}
							>
								Settings
							</MenuItem>
							<MenuItem
								onClick={() => {
									navigate("/billing");
								}}
							>
								Billing
							</MenuItem>
							<MenuDivider />
							<MenuItem onClick={handleSignout}>Sign out</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
			</HStack>
		</Flex>
	);
};

export default AccountNav;
